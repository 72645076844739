import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { createUUID } from './useUUID';
import useSleep from './useSleep';
import usePortal from './usePortal';

function Modal({ open, setOpen, children, className, showCloseButton }) {

    const { createPortal, makeDocumentNotScrollable, makeDocumentScrollable } = usePortal();
    const { sleep } = useSleep();
    const uuidRef = useRef(createUUID);
    const [modalId, setModalId] = useState(null);
    const modalRef = useRef(null);
    const [openPortal, setOpenPortal] = useState(false);

    useEffect(() => {
        const handleOpen = async () => {
            if (open) {
                setModalId(`modal_${uuidRef.current()}`);
            } else {
                await sleep(300);
            }
            setOpenPortal(open);
        }

        handleOpen();
    }, [open]);

    useEffect(() => {
        modalRef.current = document.body;
        return () => {
            makeDocumentScrollable()
        }
    }, []);

    const afterLeave = async () => {
        await sleep(35);
        makeDocumentScrollable(modalId);
    }

    return openPortal && modalRef?.current ? createPortal((
        <Transition
            show={open}
            appear={true}
            as={Fragment}
            beforeEnter={makeDocumentNotScrollable}
            afterLeave={afterLeave}
        >
            <div name='portal-modal' id={modalId} className='w-full h-full top-0 left-0 fixed overflow-y-auto z-[100] p-4 !m-0'>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
                        onClick={() => setOpen(false)} />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className='m-auto relative flex flex-col justify-end sm:justify-center sm:items-center min-h-[100%] w-auto'>
                        <div
                            className='fixed inset-0 bg-opacity-0 transition-opacity'
                            onClick={() => setOpen(false)} />
                        <div className={`sticky top-0 z-50 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl sm:my-8 sm:p-6 ${className}`}>
                            <div className='w-full flex justify-end -mt-1'>
                                {(showCloseButton === undefined || showCloseButton === true) && <button onClick={() => setOpen(false)} >
                                    <XMarkIcon className='w-5 h-5 text-gray-600 hover:text-gray-900' />
                                </button>}
                            </div>
                            {children}
                        </div>
                    </div>
                </Transition.Child>
            </div>
        </Transition>
    ), modalRef.current) : null;
}

export default Modal