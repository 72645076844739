import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MenuButton({ items, children, position, hasArrow, borderDivisible }) {

    const topCalc = {
        'top': `-${30 + (items?.length * 35)}px`
    }

    const stylesPosition = {
        'bottom': 'right-0 mt-2',
        'top': `lg:right-0`
    }

    return (
        <div className="inline-flex">
            <Menu as="div" className="-ml-px relative block z-1">
                {({ open }) => (
                    <>
                        {(() => {
                            if (!children) {
                                return (
                                    <Menu.Button className="bg-white outline-none transition-all inline-flex items-center justify-center px-6 py-2 mx-3 border border-gray-300 text-gray-500 shadow-md text-base font-medium rounded-md hover:bg-gray-50">
                                        <p>Acciones</p>
                                    </Menu.Button>
                                )
                            }
                            return (typeof children === 'string' ? (
                                <Menu.Button className="bg-white outline-none transition-all inline-flex items-center justify-center px-6 py-2 mx-3 border border-gray-300 text-gray-500 shadow-md text-base font-medium rounded-md hover:bg-gray-50">
                                    <p>{children}</p>
                                </Menu.Button>
                            ) : (
                                <Menu.Button className="shadow-none outline-none flex items-center gap-1">
                                    {children} {hasArrow && <span><ChevronDownIcon className={`w-6 h-6 text-gray-500 ${open && 'rotate-180'} transition-all duration-200`} /></span>}
                                </Menu.Button>
                            ))
                        })()}
                        {items.length > 0 && (
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 -scale-95">
                                <Menu.Items
                                    className={`w-full min-w-max w-full origin-top-right ${stylesPosition[position]} absolute rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none`}
                                    style={{ top: topCalc[position] }}>
                                    <div className="py-1" >
                                        {items.map((item, i) => (
                                            <Menu.Item
                                                disabled={item.disabled}
                                                key={i}>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => { if (item.action) item.action(item) }}
                                                        className={classNames(
                                                            item.disabled ? 'opacity-50 cursor-default' : 'opacity-100',
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            `block py-2 text-sm px-4 w-full text-left ${borderDivisible ? 'first:border-b last:border-t' : ''} border-gray-200`
                                                        )}>
                                                        {item.name}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        )}
                    </>
                )}
            </Menu>
        </div>
    )
}

MenuButton.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    position: PropTypes.string,
    hasArrow: PropTypes.bool,
    borderDivisible: PropTypes.bool
}

MenuButton.defaultProps = {
    items: [],
    position: 'bottom',
    hasArrow: false,
    borderDivisible: false,
}

export default MenuButton;