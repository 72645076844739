import React, { useState, useEffect, } from 'react';
import { getUnseenMessagesCount } from '../../apiClient/operations/mailboxOperations';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';

function MailboXMarkIcon({ user, newMessage }) {

    const [unseenCount, setUnseenCount] = useState(0);

    useEffect(() => {
        if (!user?.id) return;
        const getCount = async () => {
            try {
                const res = await getUnseenMessagesCount();
                const unseenCount = res?.unseen_count;
                setUnseenCount(unseenCount > 99 ? 99 : unseenCount);
            } catch (error) {
                console.log(error);
            }
        }

        getCount();
    }, [user?.id]);

    useEffect(() => {
        if (!newMessage) return;
        setUnseenCount(unseenCount => unseenCount + 1);
    }, [newMessage]);

    return (
        <div>
            <Link href="/buzon">
                <a className='w-7 h-7 relative flex items-center  outline-none'
                    onClick={() => setUnseenCount(0)}>
                    <EnvelopeIcon className='w-6 h-6 text-white lg:text-gray-500' />
                    {unseenCount > 0 && (
                        <div className='absolute w-5 h-5 bg-red-600 text-white -right-1 -top-1 rounded-full flex items-center justify-center text-center text-xs'>
                            {unseenCount}
                        </div>
                    )}
                </a>
            </Link>
        </div>
    )
}

export default MailboXMarkIcon