import { createPortal } from "react-dom";
import { isMobile } from 'react-device-detect';

export default function usePortal() {

    const create = (children, selector) => {
        return createPortal(children, selector);
    }

    const makeDocumentNotScrollable = () => {
        if (!window || !document) return;

        let scrollBarWidth = window.innerWidth - document.documentElement.offsetWidth;
        let paddingRight = document.documentElement.style.paddingRight;
        if (!paddingRight || paddingRight == '') {
            paddingRight = isMobile ? '0px' : `${scrollBarWidth}px`
        }
        document.documentElement.style.overflow = 'hidden';
        document.documentElement.style.paddingRight = paddingRight;
    }

    const makeDocumentScrollable = async (modalId) => {
        if (!document) return;

        let modals = document.getElementsByName("portal-modal");
        if (modalId) {
            modals =  Array.from(modals || [])?.filter(modal => modal.id != modalId)
        }
        if (!modals || modals?.length == 0) {
            let headlessuiPortal = document.getElementById("headlessui-portal-root");
            if (!headlessuiPortal) {
                document.documentElement.style.overflow = null;
                document.documentElement.style.paddingRight = null;
            }
        }
    }

    return {
        createPortal: create,
        makeDocumentNotScrollable,
        makeDocumentScrollable
    }

}