import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { getUnseenNotificationsCount, getNotifications, updateNotificationStatus } from '../../apiClient/operations/mailboxOperations';
import { Popover, Transition } from '@headlessui/react'
import { ClockIcon, InboxIcon, ArrowLeftIcon, EllipsisVerticalIcon, XMarkIcon, ExclamationCircleIcon, MegaphoneIcon, ExclamationTriangleIcon, CalendarIcon } from '@heroicons/react/24/outline'
import { BellIcon } from '@heroicons/react/24/solid'
import Image from 'next/image';
import moment from 'moment';
import MenuButton from './MenuButton';
import { dateFormatter } from './useFormatDate';
import NotificationToast from './NotificationToast';
import Badge from './Badge';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Notifications({ user, newNotification }) {

    const [unseenCount, setUnseenCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [notificationDetails, setNotificationDetails] = useState(null);
    const [notificationReceived, setNotificationReceived] = useState(null);
    const [tabs, setTabs] = useState([
        { id: 1, name: 'Todas', current: true, notificationsStatus: 'seen|unseen|read', emptyStateText: 'No hay nuevas notificaciones' },
        { id: 2, name: 'No leídas', current: false, notificationsStatus: 'seen|unseen', emptyStateText: 'No hay notificaciones' },
        { id: 3, name: 'Archivadas', current: false, notificationsStatus: 'archived', emptyStateText: 'No hay notificaciones archivadas' },
    ]);

    useEffect(() => {
        if (!user?.id) return;

        const getCount = async () => {
            try {
                const res = await getUnseenNotificationsCount();
                const unseenCount = res?.unseen_count;
                setUnseenCount(unseenCount > 99 ? 99 : unseenCount);
            } catch (error) {
                console.log(error);
            }
        }

        getCount();
        setNotifications([]);
        setShouldFetch(false);
    }, [user?.id]);

    useEffect(() => {
        if (!shouldFetch) return;
        getNotificationsData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetch, page, tabs]);

    useEffect(() => {
        if (notificationDetails) setOpenDetailsModal(true);
    }, [notificationDetails]);

    useEffect(() => {
        if (!openDetailsModal) {
            setTimeout(() => {
                setNotificationDetails(null);
            }, 300);
        }
    }, [openDetailsModal]);

    useEffect(() => {
        if (!newNotification) return;
        setUnseenCount(unseenCount => unseenCount + 1);
        setNotificationReceived({
            title: newNotification?.title,
            message: newNotification?.message
        });
        setTimeout(() => {
            setNotificationReceived(null);
        }, 20000);
        if (notifications.length > 0 || (notifications.length == 0 && shouldFetch)) {
            setNotifications(notifications => [
                {
                    ...newNotification,
                    is_new: true
                },
                ...notifications
            ]);
        }
    }, [newNotification]);

    const onOpen = () => {
        setShouldFetch(true);
        setUnseenCount(0);
    }

    const getNotificationsData = useCallback(async () => {
        try {
            setIsLoading(true);
            const status = tabs.find(tab => tab.current)?.notificationsStatus;
            const res = await getNotifications(page, 10, status);
            const data = res?.data?.map(notification => {
                return {
                    ...notification,
                    is_new: notification.status == 'unseen' || moment().diff(moment(notification.created), 'minutes') <= 60
                }
            });
            setNotifications(notifications => [
                ...notifications,
                ...data
            ])
            setHasMore(res?.has_more);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }, [user, page, tabs])

    const handleScroll = (e) => {
        if (isLoading) return;
        const skeletonLoadersHeight = 136;
        const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + skeletonLoadersHeight);
        if (bottom && hasMore) {
            setIsLoading(true);
            setPage(page + 1);
        }
    }

    const handleChangeTab = id => {
        setNotifications([]);
        const newTabs = tabs.map(tab => {
            return {
                ...tab,
                current: tab.id === id
            }
        });
        setTabs(newTabs);
        setPage(1);
    }

    return (
        <div>
            <NotificationToast
                show={notificationReceived != null}
                title={notificationReceived?.title}
                message={notificationReceived?.message} />
            <Popover>
                <Popover.Button
                    onClick={onOpen}
                    className="w-7 h-7 relative flex items-center outline-none">
                    <BellIcon className='w-6 h-6 text-white lg:text-gray-500' />
                    {unseenCount > 0 && (
                        <div className='absolute w-5 h-5 bg-red-600 text-white -right-1 -top-1 rounded-full flex items-center justify-center text-center text-xs'>
                            {unseenCount}
                        </div>
                    )}
                </Popover.Button>
                <Popover.Overlay as='div' className="fixed inset-0 bg-black h-screen z-[50] opacity-30 overflow-hidden" />

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-2"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-2"
                >
                    <Popover.Panel className="z-[99] fixed bottom-0 sm:bottom-auto sm:absolute right-0 sm:right-3 w-screen z-[99] mt-0 sm:mt-6 max-w-full sm:max-w-lg border border-gray-200 overflow-hidden overscroll-none rounded-t-2xl sm:rounded-lg" >
                        {({ close }) => (
                            <>
                                <div className="w-full flex flex-col bg-white text-sm leading-6 shadow-2xl h-[calc(100dvh)] max-h-[calc(100dvh-5rem)] sm:min-h-[calc(100vh-12rem)] sm:max-h-[calc(100vh-12rem)] sm:h-full overflow-hidden sm:max-w-3xl">
                                    <div className='flex justify-between my-1 sm:my-4 p-4 '>
                                        <h3 className='text-2xl sm:text-3xl font-semibold leading-6 text-gray-900 text-left'>
                                            Notificaciones
                                        </h3>
                                        <div
                                            className='rounded-full w-7 h-7 bg-gray-200 sm:hidden flex items-center justify-center -mt-1 -mr-1'
                                            onClick={() => close()} >
                                            <XMarkIcon
                                                className='w-5 h-5 text-gray-900'
                                                onClick={() => setOpenDetailsModal(false)} />
                                        </div>
                                    </div>
                                    <div className="border-b border-gray-200 px-4">
                                        <div className="flex space-x-8 h-8" aria-label="Tabs">
                                            {tabs.map((tab) => (
                                                <div
                                                    key={tab.id}
                                                    onClick={() => handleChangeTab(tab.id)}
                                                    className={classNames(
                                                        tab.current
                                                            ? 'border-blue-sidebar text-blue-sidebar'
                                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                        'whitespace-nowrap border-b-2 pb-2 px-1 text-sm font-medium cursor-pointer'
                                                    )}
                                                    aria-current={tab.current ? 'page' : undefined}
                                                >
                                                    {tab.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <NotificationsMailbox
                                        notifications={notifications}
                                        setNotifications={setNotifications}
                                        onScroll={handleScroll}
                                        hasMore={hasMore}
                                        isLoading={isLoading}
                                        setShouldFetch={setShouldFetch}
                                        setNotificationDetails={setNotificationDetails}
                                        closeNotifications={close}
                                        emptyStateText={tabs.find(tab => tab.current)?.emptyStateText} />

                                    <Transition
                                        show={openDetailsModal}
                                        as="div"
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-x-full"
                                        enterTo="opacity-100 translate-x-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-x-0"
                                        leaveTo="opacity-0 translate-x-full"
                                        className='w-full bg-white mt-[45px] sm:mt-[75px] absolute inset-0 z-40'
                                    >
                                        <>
                                            <div className='absolute left-0 top-0 right-0 h-6 bg-gradient-to-b from-20% from-white to-transparent z-10' />
                                            <div className='relative pt-6 px-4 pb-4 overflow-y-auto overflow-x-hidden h-full'>
                                                <div className='relative flex gap-3 items-start'>
                                                    <div className='flex items-center'>
                                                        <ArrowLeftIcon
                                                            className='w-7 h-7 cursor-pointer text-gray-900'
                                                            onClick={() => setOpenDetailsModal(false)} />
                                                    </div>
                                                    <div className='flex flex-col gap-1'>
                                                        <span className='text-lg text-gray-900 font-medium'>
                                                            {notificationDetails?.title}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span className='mt-2 text-gray-500 flex gap-2'>
                                                    <div className='flex items-center gap-1'>
                                                        <ClockIcon className='w-3 h-3' />
                                                        {dateFormatter(notificationDetails?.created, 'DD MMMM YYYY, HH:mm:ss')}
                                                    </div>
                                                </span>
                                                <div className='mt-4 flex flex-col gap-4'>
                                                    <span className='text-gray-600'>
                                                        {notificationDetails?.message}
                                                    </span>
                                                    {notificationDetails?.body && (
                                                        <span className='text-gray-600'>
                                                            {notificationDetails.body}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div >
    )
}

const typeIcons = {
    "warning": <ExclamationTriangleIcon className='w-6 h-6 text-yellow-500' />,
    "notice": <MegaphoneIcon className='w-6 h-6 text-blue-500' />,
    "fatal": <ExclamationCircleIcon className='w-6 h-6 text-red-500' />,
    "event": <CalendarIcon className='w-6 h-6 text-green-500' />
}

const typeBgColors = {
    "warning": "bg-yellow-100 border-yellow-500",
    "notice": "bg-blue-100 border-blue-500",
    "fatal": "bg-red-100 border-red-500",
    "event": "bg-green-100 border-green-500"
}

function NotificationsMailbox({ notifications, setNotifications, onScroll, hasMore, isLoading, setShouldFetch, closeNotifications, setNotificationDetails, emptyStateText }) {

    useEffect(() => {
        if (notifications.length == 0 || !notifications.some(notification => notification.status == 'unseen')) return;

        //Change the status of new notifications to seen
        const markSeenNotifications = async () => {
            let ids = [];
            let requests = [];
            const seenStatus = 'seen';
            notifications?.forEach(notification => {
                if (notification.status === 'unseen') {
                    ids.push(notification.id);
                    requests.push(updateNotificationStatus(notification.id, seenStatus));
                }
            })
            await Promise.all(requests);
            setNotifications(notifications.map(notification => {
                if (ids.includes(notification.id)) {
                    return {
                        ...notification,
                        status: seenStatus
                    }
                }
                return notification;
            }))
        }

        markSeenNotifications();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    const onClickNotification = notification => {
        if (notification.status != 'read') {
            markAsRead(notification);
            setShouldFetch(true);
        }
        if (notification.cta) return;

        if (!notification.payload || Object.keys(notification.payload)?.length === 0 || notification.payload?.details) {
            setNotificationDetails && setNotificationDetails(notification);
        } else {
            closeNotifications && closeNotifications();
        }
    }

    const markAsSeen = async (notification) => {
        const status = 'seen';
        updateNotificationStatus(notification?.id, status);
        setNotifications(notifications.map(item => {
            if (item.id == notification.id) return {
                ...item,
                status: status
            }
            return item;
        }));
    }

    const markAsRead = (notification) => {
        const status = 'read';
        updateNotificationStatus(notification?.id, status);
        setNotifications(notifications.map(item => {
            if (item.id == notification.id) return {
                ...item,
                status: status
            }
            return item;
        }));
    }

    const archive = (notification) => {
        const status = 'archived';
        updateNotificationStatus(notification?.id, status);
        setNotifications(notifications.filter(item => item.id != notification.id));
    }

    const desarchive = (notification) => {
        const status = 'read';
        updateNotificationStatus(notification?.id, status);
        setNotifications(notifications.filter(item => item.id != notification.id));
    }

    const getActions = (notification) => {
        let items = [
            {
                id: 1,
                name: "Marcar como leída",
                action: () => markAsRead(notification)
            },
            {
                id: 2,
                name: "Marcar como no leída",
                action: () => markAsSeen(notification)
            },
            {
                id: 3,
                name: "Archivar",
                action: () => archive(notification)
            },
            {
                id: 4,
                name: "Desarchivar",
                action: () => desarchive(notification)
            }
        ]
        if (notification.status == 'seen' || notification.status == 'unseen') {
            items = items.filter(item => item.id != 2 && item.id != 4);
        } else if (notification.status == 'read') {
            items = items.filter(item => item.id != 1 && item.id != 4);
        } else if (notification.status == 'archived') {
            items = [items.find(item => item.id == 4)];
        }
        return items;
    }

    const renderNotification = (notification, index) => {
        const isNew = notification.is_new;
        const items = getActions(notification);
        return (
            <div key={notification.id} >
                <a {...(notification.cta ? { href: notification.cta } : {})} className='w-full animate-[fade-in_0.5s] relative'>
                    <div onClick={() => onClickNotification(notification)}
                        className='hover:bg-gray-50 transition-all w-full py-4 pl-4 pr-12 sm:pr-10 flex justify-between items-start gap-2 text-sm cursor-pointer'>
                        <div className='flex gap-3 items-stretch'>
                            <div className={`${typeBgColors[notification.type || 'notice']} min-w-[2px] border-2`} />
                            <div className={`${typeBgColors[notification.type || 'notice']} min-w-[2.5rem] min-h-[2.5rem] w-10 h-10 rounded-md hidden sm:flex items-center justify-center overflow-hidden`}>
                                {typeIcons[notification.type || 'notice']}
                            </div>
                            <div className={`${notification.status == 'read' ? 'opacity-80' : ''} flex flex-col gap-1`}>
                                <span className='text-gray-900 font-medium'>
                                    {notification.title}
                                </span>
                                <span className='text-gray-700'>
                                    {notification.message}
                                </span>
                                <span className='text-gray-500 text-xs flex gap-2'>
                                    <div className='flex items-center gap-1'>
                                        {notification.category && (
                                            <span className='flex items-center gap-1'>
                                                {notification.category}
                                                <span className='text-lg'>·</span>
                                            </span>
                                        )}
                                        <ClockIcon className='hidden w-3 h-3' />
                                        {moment(notification.created).fromNow()}
                                    </div>
                                </span>
                            </div>
                        </div>
                        {isNew && (
                            <div className='min-w-[4rem]'>
                                <Badge text='Nueva' />
                            </div>
                        )}
                        {!isNew && (notification.status != 'read' && notification.status != 'archived') && (
                            <div className='min-w-[1rem] sm:min-w-[1rem]'>
                                <div className='min-w-[1rem] sm:min-w-[1rem] flex justify-center'>
                                    <div className='bg-blue-sidebar min-w-[0.5rem] min-h-[0.5rem] rounded-full' />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='absolute py-4 top-0 bottom-0 right-4 flex items-start'>
                        <MenuButton
                            position={(notifications?.length > 1 && index == notifications?.length - 1) ? 'top' : 'bottom'}
                            items={items}>
                            <EllipsisVerticalIcon className="min-w-[1.5rem] sm:min-w-[1.25rem] sm:h-5 text-gray-900" />
                        </MenuButton>
                    </div>
                </a>
            </div>
        )
    }

    return (
        <div onScroll={onScroll} className='w-full overflow-y-auto h-full flex-grow flex flex-col'>
            {!isLoading && notifications?.length == 0 ? (
                <div className='h-full flex-grow flex flex-col items-center justify-center'>
                    <div className='w-14 h-14 rounded-full bg-gray-200 flex items-center justify-center'>
                        <InboxIcon className='w-8 h-8 text-gray-500' />
                    </div>
                    <span className="mt-2 block text-sm text-gray-500">{emptyStateText || 'No hay notificaciones'}</span>
                </div>
            ) : (
                <div className='divide-y divide-gray-200'>
                    {notifications?.map((notification, index) => renderNotification(notification, index))}
                    {[...Array((notifications?.length > 0 && hasMore) ? 2 : isLoading ? 6 : 0).keys()].map(key => (
                        <div key={key} className='w-full'>
                            <div className='w-full p-4 text-sm cursor-pointer'>
                                <div className='w-full flex flex-row gap-3 animate-pulse'>
                                    <div className='min-w-[2.25rem] min-h-[2.25rem] rounded-md relative w-9 h-9 bg-gray-300' />
                                    <div className='flex flex-col gap-2 w-full'>
                                        <div className='bg-gray-300 w-1/2 h-3 rounded-full' />
                                        <div className='bg-gray-300 w-full h-3 rounded-full' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Notifications