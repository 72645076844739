import React, { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

function NotificationToast({ show, title, message }) {
    const [showNotification, setShowNotification] = useState(show);

    useEffect(() => {
        setShowNotification(show);
    }, [show]);

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 pt-20 flex items-start px-4 py-6 md:items-start md:p-6 z-[55]"
            >
                <div className="flex w-full flex-col items-center space-y-4 md:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={showNotification}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 md:translate-y-0 md:translate-x-2"
                        enterTo="translate-y-0 opacity-100 md:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-center">
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-lg font-medium text-gray-900">{title}</p>
                                        <p className="text-gray-600">{message}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setShowNotification(false)
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className='w-5 h-5'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

NotificationToast.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
}

NotificationToast.defaultProps = {
    show: false,
    message: 'Message'
}

export default NotificationToast