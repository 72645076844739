import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { ArrowDownCircleIcon, DocumentIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import PrimaryButton from '../hooks/PrimaryButton';
import { getTaxCompliance, getTaxStatus } from '../../apiClient/operations/taxDocuments';
import Alert from '../hooks/Alert';
import { download } from '../../apiClient/operations/s3Operations';
import { saveAs } from 'file-saver';
import Badge from '../hooks/Badge';
import { dateFormatter } from '../../util/dateFormatter';
import Tooltip from '../hooks/Tooltip';
import SkeletonLoader from '../hooks/SkeletonLoader';
import { formatCurrency } from '../../util/numberFormatter';
import Link from 'next/link';
import CustomAdvice from '../hooks/CustomAdvice';

function Home({ user }) {

    const [generalError, setGeneralError] = useState(null);
    const [situationLoader, setSituationLoader] = useState(false);
    const [complianceLoader, setComplianceLoader] = useState(false);
    const [taxStatus, setTaxStatus] = useState(null);
    const [taxCompliance, setTaxCompliance] = useState(null);
    const [loadingDocuments, setLoadingDocuments] = useState(false);

    const outcomes = {
        'POSITIVE': 'Positivo',
        'NEGATIVE': 'Negativo',
        'NO_OBLIGATIONS': 'Sin obligaciones',
    }

    const outcomesType = {
        'POSITIVE': 'Success',
        'NEGATIVE': 'Error',
        'NO_OBLIGATIONS': 'Default',
    }

    const outcomesMessage = {
        'POSITIVE': 'Significa que tu RFC está inscrito y al corriente en el cumplimiento de obligaciones fiscales.',
        'NEGATIVE': 'Un estatus negativo se refiere a que no estás al corriente en el cumplimiento de tus obligaciones ya sea por declaraciones pendientes, créditos fiscales, recargos u otros.',
        'NO_OBLIGATIONS': 'Significa que tu RFC está inscrito en el SAT pero sin obligaciones fiscales.',
    }

    const statusPadron = {
        'ACTIVO': 'Activo',
        'INACTIVO': 'Inactivo',
        'REACTIVADO': 'Reactivado',
    }

    const statusPadronType = {
        'ACTIVO': 'Success',
        'INACTIVO': 'Error',
        'REACTIVADO': 'Success',
    }

    const statusMessage = {
        'ACTIVO': 'Significa que tienes al menos una actividad económica activa registrada ante el SAT.',
        'INACTIVO': 'Significa que para el SAT no estás realizando ninguna actividad económica, ya sea porque hayas reportado ante el SAT la baja de tus actividades económicas o porque no hayas tenido actividad tributaria en los últimos dos años.',
        'REACTIVADO': 'Significa que has reanudado tus actividades económicas ante el SAT.',
    }

    const getTaxDocuments = async () => {
        setLoadingDocuments(true);

        const requests = [
            getTaxStatus(),
            getTaxCompliance()
        ]
        const results = await Promise.all(requests.map(p => p.catch(e => e)));
        const [taxStatusList, taxComplianceList] = results;

        setTaxStatus(taxStatusList?.data?.fis_account_tax_status[0]);
        setTaxCompliance(taxComplianceList?.data?.fis_account_tax_compliance[0]);

        setLoadingDocuments(false);
    }

    const downloadTaxStatusInfo = async () => {
        setSituationLoader(true);
        try {
            const getFile = await download(taxStatus?.document?.bucket, taxStatus?.document?.path);
            const name = taxStatus.document?.path?.split("/")?.pop();
            saveAs(getFile, name);
            setSituationLoader(false);
        } catch (e) {
            setSituationLoader(false);
            setGeneralError('No cuentas con con una constancia aún, comunícate con FIXAT para más información');
        }
    }

    const downloadTaxComplianceInfo = async () => {
        setComplianceLoader(true);
        try {
            const getFile = await download(taxCompliance?.document?.bucket, taxCompliance?.document?.path);
            const name = taxCompliance.document?.path?.split("/")?.pop();
            saveAs(getFile, name);
            setComplianceLoader(false);
        } catch (e) {
            setComplianceLoader(false);
            setGeneralError('No cuentas con con una opinión de cumplimiento aún, comunícate con FIXAT para más información');
        }
    }

    useEffect(() => {
        if (user?.status == 'suspended' || user?.status == 'defaulter') return

        getTaxDocuments();

        return () => {
            setTaxStatus(null);
            setTaxCompliance(null);
        }
    }, [user?.status]);

    return (
        <>
            <div className={`${generalError !== null ? 'my-2' : 'mt-2 lg:mt-0'} top-20 sticky z-[99] px-4`}>
                <Alert type="Error" show={generalError != null} title={generalError} onClose={() => setGeneralError(null)} />
            </div>
            <div className='w-full lg:hidden flex flex-wrap justify-center text-xs gap-x-6 gap-y-1 text-gray-900 pt-4 px-4 font-medium'>
                <p>
                    {user?.name}
                </p>
                <p>
                    RFC: {user?.tax_id}
                </p>
            </div>
            {(user?.status == 'suspended' || user?.status == 'defaulter') ? (
                <>
                    <div className='w-full flex justify-center pb-4 pt-0 lg:py-4 px-4 lg:px-6 mt-6'>

                        {user?.status == 'suspended' ? (
                            <div className='hidden lg:block w-[243px] bg-slate-500 h-[277px] relative rounded-2xl -mr-12 z-[9] mt-16 p-4'>
                                <div className='mt-12'>
                                    <Image
                                        src='https://cdn.fixat.mx/assets/taxpayers/duda_en_ordenador.svg'
                                        alt='Dudas'
                                        width='191px'
                                        height='245px'
                                        priority
                                    />
                                </div>
                            </div>
                        ) : (<>{user?.status == 'defaulter' &&
                            <div className='hidden lg:block w-[243px] bg-slate-500 h-[277px] relative rounded-2xl -mr-12 z-[9] mt-16 p-4'>
                                <div className='mt-12'>
                                    <Image
                                        src='https://cdn.fixat.mx/assets/taxpayers/suspira_ordenador.png'
                                        alt='Adeudo'
                                        width='268px'
                                        height='261px'
                                        priority
                                    />
                                </div>
                            </div>}</>

                        )}

                        <div>
                            <div className='w-full lg:max-w-[45rem] max-h-full sm:max-h-[19rem] rounded-2xl px-4 lg:px-10 py-0 lg:py-6 bg-gray-700'>
                                <div className='ml-0 lg:ml-auto w-full lg:w-11/12 text-white lg:text-left text-center py-6'>
                                    <span className='w-full block text-3xl font-extrabold leading-9'>
                                        {user?.status == 'suspended' ? 'Tu cuenta ha sido suspendida' : (user?.status == 'defaulter' && 'Tu cuenta presenta un adeudo')}
                                    </span>
                                    <span className='block text-lg pt-8'>
                                        Tienes un adeudo de {formatCurrency(user?.dueAmount / 100)}. Recuerda que para disfrutar de todos los beneficios deberás estar al corriente en tus pagos.
                                        {user?.status == 'defaulter' && ' Evita que tu cuenta sea suspendida.'}
                                    </span>
                                    <div className='flex gap-4 items-center pt-6'>
                                        <div className='w-full'>
                                            <Link href='/perfil-fixat/balance-pagos?estado' legacyBehavior={false}>
                                                <button
                                                    type='button'
                                                    className='text-white underline'>
                                                    Ver último estado de cuenta
                                                </button>
                                            </Link>
                                        </div>
                                        <div className='w-full '>
                                            <Link href='/perfil-fixat/balance-pagos#pago' legacyBehavior={false}>
                                                <PrimaryButton
                                                    isFullWidth>
                                                    ¡Pagar ahora!
                                                </PrimaryButton>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='w-full mt-4 lg:pl-14'>
                                <CustomAdvice
                                    type='Info'
                                    title='Si ya realizaste tu pago, puede tardar hasta 48H en verse reflejado.' />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='w-full flex justify-center pb-4 pt-0 lg:py-4 px-4 lg:px-6 mt-6'>
                        <div className='hidden lg:block w-[243px] bg-[#0F4373] h-[277px] relative rounded-2xl -mr-12 z-[9] mt-16 p-4'>
                            <div className='mt-12'>
                                <Image
                                    src='https://cdn.fixat.mx/assets/taxpayers/fixi-inicio-idea.png'
                                    alt='Fixi inicio'
                                    width='191px'
                                    height='245px'
                                />
                            </div>
                        </div>
                        <div className='w-full lg:max-w-[45rem] max-h-full sm:max-h-[18rem] rounded-2xl px-4 lg:px-10 py-0 lg:py-6 bg_blue_dots'>
                            <div className='ml-0 lg:ml-auto w-full lg:w-11/12 text-white lg:text-left text-center py-6'>
                                <span className='w-full block text-3xl font-extrabold leading-9'>
                                    ¡Enhorabuena {user?.name?.split(' ')[0]}!
                                </span>
                                <span className='block text-lg pt-8'>
                                    Te damos la bienvenida al <span className='font-bold'>portal FIXAT®</span>. Aquí sabrás el estatus de tus declaraciones, podrás facturar gratuitamente, tener acceso al detalle de tu contrato y saber tu situación fiscal.
                                </span>
                            </div>
                        </div>
                    </div>
                    {loadingDocuments ?
                        <div className='w-full mb-20 lg:my-4 flex flex-col lg:flex-row gap-y-4 gap-x-8 lg:gap-4 p-4 mx-auto max-w-screen-lg'>
                            <div className='w-full rounded-lg border border-gray-200 shadow-md p-4 bg-white flex flex-col px-4 space-y-6'>
                                <SkeletonLoader height='h-5' />
                                <SkeletonLoader height='h-5' />
                                <div className='w-full flex justify-between items-center gap-6'>
                                    <SkeletonLoader height='h-5' />
                                    <SkeletonLoader height='h-5' />
                                </div>
                            </div>
                            <div className='w-full rounded-lg border border-gray-200 shadow-md p-4 bg-white flex flex-col px-4 space-y-6'>
                                <SkeletonLoader height='h-5' />
                                <SkeletonLoader height='h-5' />
                                <div className='w-full flex justify-between items-center gap-6'>
                                    <SkeletonLoader height='h-5' />
                                    <SkeletonLoader height='h-5' />
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className='mt-6 flex gap-2 justify-center text-gray-900 leading-tight font-medium'>
                                <span>
                                    Esta es tu última actualización fiscal
                                </span>
                                <Tooltip title='Descargamos en automático esta información del SAT para que la tengas siempre actualizada, la última semana de cada mes.' />
                            </div>
                            <div className='w-full mb-20 lg:my-4 flex flex-col lg:flex-row gap-y-4 gap-x-8 lg:gap-4 p-4 mx-auto max-w-screen-lg'>
                                <div className='w-full rounded-lg border border-gray-200 shadow-md p-4 bg-white flex flex-col px-4 space-y-4'>
                                    <div className='w-full flex gap-3 items-center'>
                                        <div className='hidden lg:flex justify-center items-center'>
                                            <DocumentIcon className='w-7 h-7 text-blue-sidebar' />
                                        </div>
                                        <span className='text-gray-900 font-semibold text-base'>Constancia de Situación Fiscal</span>
                                    </div>
                                    <div className='text-gray-900 text-sm'>
                                        {taxStatus === null || taxStatus === undefined ? 'Aún no cuentas con constancia registrada, comunícate con nosotros.' : `Última actualización: ${dateFormatter(taxStatus?.collected_at, 'DD MMM YYYY, HH:mm:ss')}`}
                                    </div>
                                    <div className='w-full flex flex-col md:flex-row gap-4 md:gap-2 justify-between md:items-center'>
                                        <div className='flex gap-2 items-center'>
                                            {taxStatus !== null && taxStatus !== undefined &&
                                                <>
                                                    <span className='text-sm text-gray-900'>
                                                        Estatus:
                                                    </span>
                                                    <Badge
                                                        text={statusPadron[taxStatus?.tax_payer_information?.status_padron]}
                                                        type={statusPadronType[taxStatus?.tax_payer_information?.status_padron]} />
                                                    <Tooltip title={statusMessage[taxStatus?.tax_payer_information?.status_padron]} />
                                                </>
                                            }
                                        </div>
                                        <PrimaryButton type='button' onClick={downloadTaxStatusInfo} disabled={situationLoader || taxStatus === null || taxStatus === undefined}>
                                            {situationLoader ?
                                                <span className="flex items-center gap-4">
                                                    <svg className="inline w-6 h-6 text-gray-500 animate-spin fill-white" viewBox="0 0 100 101" fill="current" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    Descargando
                                                </span>
                                                :
                                                <span className='flex gap-4'><ArrowDownCircleIcon className={`w-6 h-6 ${taxStatus === null || taxStatus === undefined ? 'text-gray-500' : 'text-white'}`} />Descargar</span>
                                            }
                                        </PrimaryButton>
                                    </div>
                                </div>
                                <div className='w-full rounded-lg border border-gray-200 shadow-md p-4 bg-white flex flex-col px-4 space-y-4'>
                                    <div className='w-full flex gap-3 items-center'>
                                        <div className='hidden lg:flex justify-center items-center'>
                                            <IdentificationIcon className='w-7 h-7 text-blue-sidebar' />
                                        </div>
                                        <span className='text-gray-900 font-semibold text-base'>Opinión de Cumplimiento</span>
                                    </div>
                                    <div className='text-gray-900 text-sm'>
                                        {taxCompliance === null || taxCompliance === undefined ? 'Aún no cuentas con opinión de cumplimento registrada, comunícate con nosotros.' : `Última actualización: ${dateFormatter(taxCompliance?.collected_at, 'DD MMM YYYY, HH:mm:ss')}`}
                                    </div>
                                    <div className='w-full flex flex-col md:flex-row gap-4 md:gap-2 justify-between md:items-center'>
                                        <div className='flex items-center gap-2'>
                                            {taxCompliance !== null && taxCompliance !== undefined &&
                                                <>
                                                    <span className='text-sm text-gray-900'>
                                                        Estatus:
                                                    </span>
                                                    <Badge
                                                        text={outcomes[taxCompliance.outcome]}
                                                        type={outcomesType[taxCompliance.outcome]} />
                                                    <Tooltip title={outcomesMessage[taxCompliance.outcome]} />
                                                </>
                                            }
                                        </div>
                                        <PrimaryButton type='button' onClick={downloadTaxComplianceInfo} disabled={complianceLoader || taxCompliance === null || taxCompliance === undefined}>
                                            {complianceLoader ?
                                                <span className="flex items-center gap-4">
                                                    <svg className="inline w-6 h-6 text-gray-500 animate-spin fill-white" viewBox="0 0 100 101" fill="current" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    Descargando
                                                </span>
                                                :
                                                <span className='flex gap-4'><ArrowDownCircleIcon className={`w-6 h-6 ${taxCompliance === null || taxCompliance === undefined ? 'text-gray-500' : 'text-white'}`} />Descargar</span>
                                            }
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            )}
        </>
    )
}

export default Home
