import React, { useState, Fragment, useEffect, cloneElement } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { Bars3Icon, HomeIcon, BriefcaseIcon, CalculatorIcon, CurrencyDollarIcon, ChatBubbleLeftIcon, IdentificationIcon, CalendarIcon } from '@heroicons/react/24/solid';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import MenuButton from '../components/hooks/MenuButton';
import { dateFormatter } from '../components/hooks/useFormatDate';
import PropTypes from 'prop-types';
import Notifications from '../components/hooks/Notifications';
import MailboXMarkIcon from '../components/mailbox/MailboxIcon'
import SwitchProfile from '../components/home/SwitchProfile';
import useUser from '../components/hooks/useUser';
import Loader from '../components/hooks/Loader';
import { switchAccount as switchSessionAccount, logout, getAccount } from '../apiClient/operations/accountsOperations';
import { getImageSize } from 'react-image-size';
import { getFirebaseToken, onFirebaseMessage } from '../firebase/init';
import { registerToken } from '../apiClient/operations/mailboxOperations';
import { updateUser } from '../apiClient/operations/accountsOperations';
import { getOverview } from '../apiClient/operations/paymentOperations';
import Home from '../components/home/Home';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function HomeLayout({ children, ...props }) {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { user, mutateUser } = useUser();
    const [newMessage, setNewMessage] = useState(null);
    const [newNotification, setNewNotification] = useState(null);
    const [loader, setLoader] = useState(null);
    const router = useRouter();
    const pathname = router.pathname;
    const [loaderDom, setLoaderDom] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 80, height: 80 });
    const suspendedPages = [
        '/situacion-fiscal/constancia',
        '/situacion-fiscal/cumplimiento',
        '/situacion-fiscal/accesos-sat',
        '/declaraciones/historico',
        '/declaraciones/ingresos',
        '/declaraciones/egresos',
        '/declaraciones/egresos-extranjero',
        '/facturacion',
        '/facturacion/inicio',
        '/facturacion/crear',
        '/facturacion/informacion-fiscal',
        '/facturacion/[invoiceId]'
    ]

    const [navigation, setNavigation] = useState([
        { id: 1, name: 'Inicio', icon: HomeIcon, current: true, route: '/', paths: ['/'] },
        {
            id: 2,
            name: 'Perfil FIXAT',
            icon: IdentificationIcon,
            current: false,
            route: '/perfil-fixat/balance-pagos',
            paths: [
                '/perfil-fixat/balance-pagos',
                '/perfil-fixat/mi-plan',
                '/perfil-fixat/informacion'
            ]
        },
        /*{ id: 2, name: 'Información fiscal', icon: IdentificationIcon, current: false, route: '/perfil/informacion-general', paths: ['/perfil/informacion-general', '/perfil/accesos-sat', '/perfil/contrato', '/perfil/pagos', '/perfil/facturas'] }*/,
        { id: 3, name: 'Situación fiscal', icon: BriefcaseIcon, current: false, route: '/situacion-fiscal/constancia', paths: ['/situacion-fiscal/constancia', '/situacion-fiscal/cumplimiento', '/situacion-fiscal/accesos-sat'] },
        {
            id: 5,
            name: 'Declaraciones',
            icon: CalculatorIcon,
            current: false,
            route: '/declaraciones',
            paths: ['/declaraciones', '/declaraciones/ingresos', '/declaraciones/egresos', '/declaraciones/egresos-extranjero', '/declaraciones/declaracion-mensual', '/declaraciones/facturas/[invoiceId]', '/declaraciones/declaracion-anual'],
            children: [
                { id: 1, name: 'Ingresos y egresos', current: false, route: '/declaraciones/ingresos', paths: ['/declaraciones/historico', '/declaraciones/ingresos', '/declaraciones/egresos', '/declaraciones/egresos-extranjero', '/declaraciones/facturas/[invoiceId]'] },
                { id: 2, name: 'Declaración mensual', current: false, route: '/declaraciones/declaracion-mensual', paths: ['/declaraciones/declaracion-mensual'] },
                { id: 3, name: 'Declaración anual', current: false, route: '/declaraciones/declaracion-anual', paths: ['/declaraciones/declaracion-anual'] },
            ]
        },
        {
            id: 6,
            name: 'Facturación',
            icon: CurrencyDollarIcon,
            current: false,
            route: '/facturacion',
            paths: ['/facturacion', '/facturacion/clientes', '/facturacion/productos', '/facturacion/crear', '/facturacion/inicio', '/facturacion/informacion-fiscal', '/facturacion/[...path]'],
            children: [
                { id: 1, name: 'Gestion de facturas', current: false, route: '/facturacion', paths: ['/facturacion', '/facturacion/crear'], needCerts: true },
                { id: 2, name: 'Clientes', current: false, route: '/facturacion/clientes', paths: ['/facturacion/clientes'], needCerts: true },
                { id: 3, name: 'Productos/Servicios', current: false, route: '/facturacion/productos', paths: ['/facturacion/productos'], needCerts: true },
                { id: 4, name: 'Ajustes', current: false, route: '/facturacion/ajustes/informacion', paths: ['/facturacion/ajustes', '/facturacion/ajustes/informacion', '/facturacion/ajustes/certificados'], needCerts: true },
                { id: 5, name: 'Inicio', current: false, route: '/facturacion/inicio', paths: ['/facturacion/inicio', '/facturacion/informacion-fiscal'], needCerts: false },
            ],
        },
    ]);

    const validateRedirect = () => {
        navigation.forEach(nav => {
            if (nav.id === 6) {
                nav.children.forEach(subitem => {
                    if ((subitem?.needCerts && !user?.hasCerts) && subitem.paths.some(route => route === router.pathname)) router.push('/')
                })
            }
        })
    }

    const changeCurrentNavigation = () => {

        const cleanedNav = navigation.map(nav => {
            if (nav.children) {
                const subItems = nav.children.map(subitem => {
                    return {
                        ...subitem,
                        current: subitem.paths.some(item => item == router.pathname)
                    }
                });
                return {
                    ...nav,
                    current: nav.paths.some(item => item == router.pathname || `${item}/${router.pathname.split('/').at(-1)}` == router.pathname),
                    children: subItems
                }
            } else {
                return {
                    ...nav,
                    current: nav.paths.some(item => item == router.pathname || `${item}/${router.pathname.split('/').at(-1)}` == router.pathname)
                }
            }
        });
        setNavigation(cleanedNav);
        setSidebarOpen(false);
    }

    const menuItems = [
        {
            name: <p className='line-clamp-2 cursor-default'>Sesión iniciada como <span className='font-semibold block text-gray-600'>{user?.email}</span></p>,
            action: () => { }
        },
        {
            name: 'Ajustes de la cuenta',
            action: () => router.push('/mi-cuenta/ajustes')
        },
        {
            name: 'Cerrar sesión',
            action: () => mutateUser(logout())
        },
    ];

    const getDimension = async () => {
        try {
            if (user?.image) {
                const dimensions = await getImageSize(user.image);
                setImageDimensions({ width: dimensions.width, height: dimensions.height })
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        changeCurrentNavigation()
    }, [router.pathname]);

    useEffect(() => {
        if (!user) return

        const getBalance = async () => {
            try {
                const overiew = await getOverview();
                let dueAmount = overiew.amount.current_due_amount || 0;

                if (dueAmount <= 0) {
                    dueAmount = 0;
                }
                await mutateUser(updateUser({
                    "dueAmount": dueAmount
                }));
            } catch (error) {
                console.log(error);
                await mutateUser(updateUser({
                    "dueAmount": 0
                }));
            }
        }

        const getToken = async (user) => {
            if (!user.fcm_token) {
                try {
                    const token = await getFirebaseToken();
                    if (token) {
                        await mutateUser(registerToken(token));
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            getMessages();
        }

        const validateStatus = async (user) => {
            let userStatus = user?.status;
            if (Object.keys(router.components).length <= 2) {
                try {
                    const account = await getAccount();
                    if (userStatus != account.status) {
                        userStatus = account.status;
                        await mutateUser(updateUser({
                            "status": userStatus
                        }));
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            if (userStatus == 'suspended' || userStatus == 'defaulter') {
                getBalance();
            } else if (!user?.admin && userStatus == 'canceled') {
                mutateUser(logout())
            }
        }

        if (user.isLoggedIn) {
            setLoaderDom(true);
            validateStatus(user);
            if (!user.admin) {
                getToken(user);
            }
        }

        getDimension();
        validateRedirect();
    }, [user?.id])

    const getMessages = () => {
        onFirebaseMessage(payload => {
            if (payload?.data?.notification) {
                const notification = JSON.parse(payload.data.notification);
                setNewNotification(notification);
                setNewMessage(notification.message_id);
                setTimeout(() => {
                    setNewMessage(null);
                    setNewNotification(null);
                }, 3000);
            }
        })
    }

    const date = new Date();

    return (
        <>
            {loaderDom &&
                <>
                    <Loader show={loader != null} title={loader} message='Esto puede demorar unos segundos...' />
                    <Transition.Root show={sidebarOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                            </Transition.Child>
                            <div className="fixed inset-0 flex z-40">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transition ease-in-out duration-300 transform"
                                    enterFrom="-translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transition ease-in-out duration-300 transform"
                                    leaveFrom="translate-x-0"
                                    leaveTo="-translate-x-full"
                                >
                                    <Dialog.Panel className="relative flex flex-col max-w-lg w-full py-4 bg-blue-sky">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-in duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in-out duration-300"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                                <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full outline-none" onClick={() => setSidebarOpen(false)}>
                                                    <span className="sr-only">Cerrar menú</span>
                                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </Transition.Child>
                                        <div className='w-full relative h-screen flex flex-col items-center overflow-hidden'>
                                            <div className='w-full h-full flex flex-col justify-between'>
                                                <div className="flex-shrink-0 flex items-center justify-start px-4 relative">
                                                    <Image
                                                        width='152.1px'
                                                        height='39.6px'
                                                        src="https://cdn.fixat.mx/intranet/logo-principal-fixat.png"
                                                        alt="Usuario operativo de Fixat"
                                                    />
                                                    <div className='absolute left-0 -bottom-6 right-0 h-6 bg-gradient-to-b from-30% from-blue-sky to-transparent z-10' />
                                                </div>
                                                <div className="pt-4 flex-1 h-0 overflow-y-auto">
                                                    <Nav navigation={navigation} setLoader={setLoader} user={user} mutateUser={mutateUser} router={router} />
                                                </div>
                                                <div className='w-full'>
                                                    <div className='h-[1px] bg-white' />
                                                    <div className="w-full flex pt-4 px-4 bg-blue-sky">
                                                        <div className="w-full flex items-center">
                                                            <div className='w-full min-w-[8rem] flex items-center justify-start gap-3'>
                                                                <div
                                                                    className={`w-14 h-14 min-w-[3.5rem] min-h-[3.5rem] rounded-full flex justify-center items-center text-lg font-medium ${!(user?.image) ? 'bg-gray-600 text-white' : `${(Number(imageDimensions.width) > Number(imageDimensions.height) && Number(imageDimensions.width < 980)) ? 'bg-contain' : 'bg-cover'} text-transparent bg-contain bg-center bg-no-repeat bg-blue-sky`}`}
                                                                    style={{ backgroundImage: `url(${user?.image})` }}>
                                                                    {user?.first_name?.charAt(0)}
                                                                    {user?.legal_type === 'personal' && user?.last_name?.charAt(0)}
                                                                </div>
                                                                <div className='text-left'>
                                                                    <p className='text-white cursor-pointer hover:text-white/90 font-medium transition-all'>
                                                                        {`${user?.first_name} ${user?.last_name}`}
                                                                    </p>
                                                                    <Link href='/mi-cuenta/ajustes'>
                                                                        <a className="text-gray-300 text-sm cursor-pointer hover:text-gray/400 font-normal transition-all">
                                                                            Ajustes de la cuenta
                                                                        </a>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className='text-white px-4 py-2 font-medium mt-1' type='button' onClick={() => router.push('/inicia-sesion')}>
                                                        Cerrar sesión
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                                <div className="flex-shrink-0 w-14" aria-hidden="true" />
                            </div>
                        </Dialog>
                    </Transition.Root>
                    <div className='flex'>
                        <div className="w-[13rem] min-w-[13rem] hidden lg:flex flex-grow flex-col min-h-0 h-screen sticky top-0 z-[10] overflow-y-auto bg-blue-sky pt-4 pb-4">
                            <div className="flex flex-shrink-0 items-center justify-center">
                                <Image
                                    width='152.1px'
                                    height='39.6px'
                                    src="https://cdn.fixat.mx/intranet/logo-principal-fixat.png"
                                    alt="Usuario operativo de Fixat"
                                />
                            </div>
                            <div className="w-full pt-4 flex flex-grow flex-col overflow-y-auto">
                                <Nav navigation={navigation} setLoader={setLoader} user={user} mutateUser={mutateUser} router={router} />
                            </div>
                            <div className='w-full p-2'>
                                <Link href='/contactanos'>
                                    <a className={`w-full p-2 rounded-lg text-sm flex justify-start gap-2 items-center text-center cursor-pointer text-white font-medium ${router.pathname === '/contactanos' && 'bg-hover-blue-sidebar'} transition-all hover:bg-hover-blue-sidebar`}>
                                        <ChatBubbleLeftIcon className='w-7 h-7' /> Contáctanos
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-blue-sky shadow bg-blue-sky lg:bg-white">
                                <div className="flex-1 px-0 lg:px-4 flex justify-between lg:justify-end items-center">
                                    <div className='block lg:hidden pt-1.5 pl-3' onClick={() => router.push('/')}>
                                        <Image
                                            width='152.1px'
                                            height='39.6px'
                                            src="https://cdn.fixat.mx/intranet/logo-principal-fixat.png"
                                            alt="Usuario operativo de Fixat"
                                        />
                                    </div>
                                    <div className='lg:w-full h-full flex lg:justify-between justify-end lg:items-center'>
                                        <div className='hidden lg:flex gap-2 items-center'>
                                            <CalendarIcon className='w-4 h-4 text-gray-500' />
                                            <span className='text-gray-500 text-sm'>
                                                {dateFormatter(date, 'LL')}
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-2 lg:space-x-8 lg:divide-x divide-blue-sidebar">
                                            <div className='flex items-center space-x-4 lg:space-x-8'>
                                                <MailboXMarkIcon user={user} newMessage={newMessage} />
                                                <Notifications user={user} newNotification={newNotification} />
                                            </div>
                                            <div className='hidden gap-4 lg:flex'>
                                                <div className='flex flex-col justify-center text-gray-900 font-medium ml-8'>
                                                    <span>
                                                        {`${user?.first_name} ${user?.last_name}`}
                                                    </span>
                                                    <span className='text-sm text-gray-500'>
                                                        RFC: {user?.tax_id}
                                                    </span>
                                                </div>
                                                <MenuButton items={menuItems} position='bottom' hasArrow borderDivisible>
                                                    <div className='flex items-center gap-1'>
                                                        <div className={`w-11 h-11 min-w-[2.75rem] min-h-[2.75rem] rounded-full flex justify-center items-center text-lg font-medium ${!user?.image ? 'bg-gray-600 text-white' : `${(Number(imageDimensions.width) > Number(imageDimensions.height) && Number(imageDimensions.width < 980)) ? 'bg-contain' : 'bg-cover'} text-transparent bg-contain bg-center bg-no-repeat bg-blue-sky`}`} style={{ backgroundImage: `url("${user?.image}")` }}>
                                                            {user?.first_name?.charAt(0)}
                                                            {user?.legal_type === 'personal' && user?.last_name.charAt(0)}
                                                        </div>
                                                    </div>
                                                </MenuButton>
                                            </div>
                                            <div className='p-2 h-full flex items-center lg:hidden gap-4'>
                                                <div className='h-full flex items-center justify-center w-12 bg-[#F9FAFB]/20 rounded-lg'>
                                                    <button type="button" className="text-white focus:outline-none" onClick={() => setSidebarOpen(true)}>
                                                        <span className="sr-only">Abrir menú</span>
                                                        <Bars3Icon className="h-6 w-7" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <main className='bg-gray-100 min-h-[calc(100dvh-4rem)] h-full'>
                                {(user?.status == 'suspended' && suspendedPages.includes(pathname)) ? (
                                    <Home user={user} />
                                ) : (
                                    React.Children.map(children,
                                        child => (
                                            cloneElement(child, { user, ...props })
                                        ))
                                )}
                            </main>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

function Nav({ navigation, setLoader, user, mutateUser, router }) {

    const [selected, setSelected] = useState(null);
    const [accounts, setAccounts] = useState(user?.accounts);

    const getDimension = async (image) => {
        try {
            const dimensions = await getImageSize(image);
            return { width: dimensions.width, height: dimensions.height }
        } catch (e) {
            console.log(e);
            return {
                width: 80,
                height: 79
            }
        }
    }

    const appendSizeImageAccount = async (accounts) => {
        const newAccounts = Promise.all(accounts.map(async (account) => {
            if (account?.image !== undefined && account?.image !== null) {
                return {
                    ...account,
                    dimensions: await getDimension(account?.image)
                }
            } else {
                return account
            }
        }));
        return newAccounts
    }

    const switchAccount = async () => {
        setLoader('Cambiando perfil fiscal...');
        try {
            await mutateUser(switchSessionAccount(selected.id));
            setLoader(null);
        } catch (e) {
            console.log(e);
            setLoader(null);
        }
    }

    const initializeValues = async () => {
        if (user?.accounts === undefined) return
        const finalAccounts = await appendSizeImageAccount(user.accounts);
        setAccounts(finalAccounts);
        setSelected(finalAccounts?.filter(account => account.id === user?.id)[0]);
    }

    useEffect(() => {
        if (!user || user?.accounts?.length < 2) return

        initializeValues();

    }, [user]);

    useEffect(() => {
        if (!user || !selected) return

        user?.id !== selected?.id && switchAccount();
    }, [selected]);

    return (
        <nav className="flex-1 bg-blue-sky px-2 overflow-y-auto" aria-label="Sidebar">
            {accounts?.length > 1 &&
                <SwitchProfile accounts={accounts} selected={selected} setSelected={setSelected} />
            }
            {navigation.map((item) =>
                !item.children ? (
                    <Link key={item.name} href={item.route} className={`${user?.status == 'suspended' && 'pointer-events-none bg-black'}`}>
                        <a className={`${item.current ? 'bg-hover-blue-sidebar' : 'bg-blue-sky hover:bg-hover-blue-sidebar'} ${(user?.status == 'suspended' && item.name == 'Situación fiscal') && 'pointer-events-none opacity-10'} transition-all text-white group w-full flex items-center my-2 text-sm rounded-lg cursor-pointer`}>
                            <div className='group w-full flex items-center pl-2 py-2 text-sm rounded-lg text-white'>
                                <item.icon className={`${item.current ? 'text-white' : 'text-gray-300/50'} mr-3 flex-shrink-0 h-6 w-6`} aria-hidden="true" />
                                {item.name}
                            </div>
                        </a>
                    </Link>
                ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                        {({ open }) => (
                            <>
                                <Disclosure.Button
                                    className={classNames(
                                        item.current
                                            ? 'bg-hover-blue-sidebar'
                                            : 'bg-blue-sky hover:bg-hover-blue-sidebar',
                                        'transition-all group w-full flex items-center text-white pl-2 pr-1 py-2 mb-2 text-left text-sm rounded-lg',
                                        user?.status == 'suspended' && 'pointer-events-none opacity-10'
                                    )}

                                >
                                    <item.icon className={`${item.current ? 'text-white' : 'text-gray-300/50'} mr-3 h-6 w-6 flex-shrink-0`} aria-hidden="true" />
                                    <span className="flex-1">{item.name}</span>
                                    <ChevronDownIcon className={classNames(open && 'rotate-180',
                                        'h-5 w-5 flex-shrink-0 text-white transform transition duration-150 ease-in-out'
                                    )} />
                                </Disclosure.Button>
                                <Transition
                                    enter="transition-all ease-in"
                                    enterFrom="max-h-0 opacity-0"
                                    enterTo="max-h-[500px] opacity-100"
                                    leave="transition-all ease-out"
                                    leaveFrom="max-h-[500px] opacity-100"
                                    leaveTo="max-h-0 opacity-0"
                                >
                                    <Disclosure.Panel className={`pl-5`} >
                                        {user?.hasCerts ?
                                            <>
                                                {item.children.filter(x => x.needCerts || x?.needCerts === undefined).map((subItem) => (
                                                    <Link key={subItem.id} href={subItem.route}>
                                                        <a className={`${subItem.current ? 'bg-hover-blue-sidebar' : 'bg-blue-sky hover:bg-hover-blue-sidebar'} transition-all group flex w-full items-center rounded-lg py-2 my-2 pl-6 pr-2 text-sm font-medium text-white`}>
                                                            {subItem.name}
                                                        </a>
                                                    </Link>
                                                ))}
                                            </>
                                            :
                                            <>
                                                {item.children.filter(x => !x.needCerts || x?.needCerts === undefined).map((subItem) => (
                                                    <Link key={subItem.id} href={subItem.route}>
                                                        <a className={`${subItem.current ? 'bg-hover-blue-sidebar' : 'bg-blue-sky hover:bg-hover-blue-sidebar'} transition-all group flex w-full items-center rounded-lg py-2 my-2 pl-6 pr-2 text-sm font-medium text-white`}>
                                                            {subItem.name}
                                                        </a>
                                                    </Link>
                                                ))}
                                            </>
                                        }
                                    </Disclosure.Panel>
                                </Transition>
                            </>
                        )}
                    </Disclosure>
                )
            )}
            <Link href='/contactanos'>
                <a className={`lg:hidden mb-2 w-full p-2 rounded-lg text-sm flex justify-start gap-2 items-center text-center cursor-pointer text-white font-medium ${router.pathname === '/contactanos' && 'bg-hover-blue-sidebar'} transition-all hover:bg-hover-blue-sidebar`}>
                    <ChatBubbleLeftIcon className={`w-7 h-7 ${router.pathname === '/contactanos' ? 'text-white' : 'text-gray-300/50'}`} /> Contáctanos
                </a>
            </Link>
        </nav>
    )
}

HomeLayout.propTypes = {
    children: PropTypes.any
}

Nav.propTypes = {
    navigation: PropTypes.array
}

export default HomeLayout