import React from 'react';
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';

function CustomAdvice({ type, message, title }) {

    const colorAdvice = {
        'Info': 'bg-blue-50 text-blue-800',
        'Success': 'bg-green-50 text-green-800',
        'Warning': 'bg-amber-50 text-amber-800',
        'Error': 'bg-red-50 text-red-800',
        'Default': 'bg-gray-50 text-gray-800'
    }

    const iconAdvice = {
        'Info': <InformationCircleIcon className='w-5 h-5 text-blue-400' />,
        'Success': <CheckCircleIcon className='w-5 h-5 text-green-400' />,
        'Warning': <ExclamationTriangleIcon className='w-5 h-5 text-amber-400' />,
        'Error': <XCircleIcon className='w-5 h-5 text-red-400' />,
        'Default': <QuestionMarkCircleIcon className='w-5 h-5 text-gray-400' />,
    }

    return (
        <div className={`w-full rounded-md p-4 text-sm font-normal flex gap-4 items-start ${colorAdvice[type] || 'bg-gray-100 text-gray-800'}`}>
            <div>
                {iconAdvice[type]}
            </div>
            <div>
                {title && <h3 className='font-medium mb-1'>{title}</h3>}
                <p>
                    {message}
                </p>
            </div>
        </div>
    )
}

CustomAdvice.propTypes = {
    type: PropTypes.string,
}

CustomAdvice.defaultProps = {
    type: 'Default',
    message: ''
}

export default CustomAdvice