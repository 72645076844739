import React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline';
import PrimaryButton from '../hooks/PrimaryButton';
import PropTypes from 'prop-types';

function SuccessfullModalContent({ title, setOpenModal }) {
    return (
        <div className='w-full p-4 text-center space-y-6'>
            <div className='w-12 h-12 mx-auto flex justify-center items-center rounded-full bg-green-100 text-green-600'>
                <CheckIcon className='w-8 h-8' />
            </div>
            <div className='text-gray-900 font-medium text-lg'>
                {title}
            </div>
            <PrimaryButton isFullWidth onClick={ () => setOpenModal(false) }>
                Aceptar
            </PrimaryButton>
        </div>
    )
}

SuccessfullModalContent.propTypes = {
    title: PropTypes.string,
    setOpenModal: PropTypes.func
}

SuccessfullModalContent.defaultProps = {
    title: 'Actualizado correctamente'
}

export default SuccessfullModalContent