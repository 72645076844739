import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import usePortal from './usePortal';
import useSleep from './useSleep';

function Loader({ show, title, message }) {

    const { createPortal, makeDocumentNotScrollable, makeDocumentScrollable } = usePortal();
    const { sleep } = useSleep();
    const [openPortal, setOpenPortal] = useState(false);
    const loaderRef = useRef(null);

    useEffect(() => {
        const handleOpen = async () => {
            if (!show) {
                await sleep(300);
            }
            setOpenPortal(show);
        }

        handleOpen();
    }, [show]);

    useEffect(() => {
        loaderRef.current = document.body;
        return () => {
            makeDocumentScrollable()
        }
    }, []);

    return openPortal && loaderRef?.current ? createPortal((
        <Transition.Root
            show={show}
            appear={true}
            beforeEnter={makeDocumentNotScrollable}
            afterLeave={makeDocumentScrollable}
        >
            <div className='relative z-[999]'>
                <Transition.Child
                    enter="ease-out duration-100"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur transition-opacity" />
                </Transition.Child>
            </div>
            <div className="fixed z-[999] inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                    <Transition.Child
                        enter="ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex-grow items-center relative w-full h-48 animate-wiggle">
                                <Image
                                    src='https://cdn.fixat.mx/assets/invoicing/fixi-silla.png'
                                    alt='Cargando'
                                    layout='fill'
                                    objectFit='contain'
                                />
                            </div>
                            <h2 className='text-white mt-4 text-2xl lg:text-3xl font-semibold'>{title}</h2>
                            <h4 className='text-white mt-4 max-w-[24rem] text-lg lg:text-xl'>{message}</h4>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition.Root>
    ), loaderRef.current) : null;
}

Loader.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
}

Loader.defaultProps = {
    show: false,
    title: "Procesando..."
}

export default Loader;